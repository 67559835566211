<template>
  <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.09741 9.62501L12.2224 6.50001M12.2224 6.50001L9.09741 3.37501M12.2224 6.50001H4.72241M6.59741 9.62501C6.59741 9.80975 6.59741 9.90211 6.59055 9.98213C6.51918 10.8137 5.9067 11.498 5.08806 11.6608C5.0093 11.6765 4.91742 11.6867 4.73388 11.7071L4.0955 11.778C3.13646 11.8846 2.65692 11.9378 2.27595 11.8159C1.768 11.6534 1.3533 11.2822 1.13565 10.7953C0.972412 10.4301 0.972412 9.94766 0.972412 8.9827V4.01732C0.972412 3.05236 0.972412 2.56988 1.13565 2.20471C1.3533 1.71781 1.768 1.34664 2.27595 1.18409C2.65692 1.06218 3.13645 1.11546 4.0955 1.22202L4.73388 1.29295C4.91749 1.31335 5.00929 1.32355 5.08806 1.33921C5.9067 1.50198 6.51918 2.18628 6.59055 3.01789C6.59741 3.0979 6.59741 3.19027 6.59741 3.37501" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSignOut'
}
</script>
